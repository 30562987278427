import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    timeoutDelay: { type: Number, default: 3500 },
  }

  connect() {
    this.timeout = setTimeout(this.removeFlash, this.timeoutDelayValue);
  }

  disconnect() {
    clearTimeout(this.timeout);
  }

  removeFlash = () => {
    this.element.parentNode.removeChild(this.element);
  }

  handleClick = (e) => {
    e.preventDefault();
    this.removeFlash();
  }
}
