import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['billingSchemeSelect', 'tieredFields'];

  connect() {
    this.toggleFields();
  }

  toggleFields() {
    const isTiered = this.billingSchemeSelectTarget.value === 'tiered';
    this.tieredFieldsTargets.forEach((field) => {
      const element = field;
      element.style.display = isTiered ? 'block' : 'none';
    });
  }
}
