import { Controller } from 'stimulus';
import "ninja-keys";

const formatParam = (name, value) => {
  return new URLSearchParams({ [name]: value }).toString();
}

const quickJumpActions = [
  {
    id: "company-details", title: "Company Details", section: "Quick Jump", handler: () => {
      const companyId = prompt("Enter Company ID");
      if (companyId) {
        Turbo.visit(`/companies/${companyId}`);
      }
    }
  },
  {
    id: "company-search", title: "Company Search", section: "Quick Jump", handler: () => {
      const query = prompt("Enter company name");
      if (query) {
        Turbo.visit(`/companies?${formatParam('q', query)}`);
      }
    }
  },
  {
    id: "user-details", title: "User Details", section: "Quick Jump", handler: () => {
      const userId = prompt("Enter User ID");
      if (userId) {
        Turbo.visit(`/users/${userId}`);
      }
    }
  },
  {
    id: "user-search", title: "User Email Search", section: "Quick Jump", handler: () => {
      const email = prompt("Enter Email");
      if (email) {
        Turbo.visit(`/users?${formatParam('q', email)}`);
      }
    }
  }
]

export default class extends Controller {
  static values = {
    navSelector: String,
    mainSelector: String,
    singlePageNavLinksSelector: String,
  }

  connect() {
    const nav = this.#dataFromNavbar();
    const main = this.#dataFromMain();
    const singlePageNavLinks = this.#dataFromSinglePageNavLinks();
    this.element.data = [...quickJumpActions, ...singlePageNavLinks, ...nav, ...main];
  }

  #generateId(link) {
    return link.text.replace(/[^a-zA-Z0-9]+/g, '-').toLowerCase()
  }

  #dataFromNavbar() {
    if (!this.hasNavSelectorValue || !document.querySelector(this.navSelectorValue)) {
      return [];
    }

    const nav = document.querySelector(this.navSelectorValue);
    return Array.from(nav.querySelectorAll('a:not([data-hotkeys-skip])')).map((link) => {
      const id = link.id || this.#generateId(link);
      const title = link.dataset.ninjaTitle || link.text;
      const section = link.dataset.ninjaSection || 'General';
      return { id, title, section, handler: () => { Turbo.visit(link.href); } }
    });
  }

  #dataFromMain() {
    if (!this.hasMainSelectorValue || !document.querySelector(this.mainSelectorValue)) {
      return [];
    }

    const main = document.querySelector(this.mainSelectorValue);
    return Array.from(main.querySelectorAll('a:not([data-hotkeys-skip])')).map((link) => {
      const id = link.id || this.#generateId(link);
      const title = link.dataset.ninjaTitle || link.text;
      const section = link.dataset.ninjaSection || 'General';
      return { id, title, section, handler: () => { Turbo.visit(link.href); } }
    });
  }

  #dataFromSinglePageNavLinks() {
    if (!this.hasSinglePageNavLinksSelectorValue || !document.querySelector(this.singlePageNavLinksSelectorValue)) {
      return [];
    }

    const main = document.querySelector(this.singlePageNavLinksSelectorValue);
    return Array.from(main.querySelectorAll('a:not([data-hotkeys-skip])')).map((link) => {
      const id = link.id || this.#generateId(link);
      const title = link.dataset.ninjaTitle || link.text;
      const section = link.dataset.ninjaSection || 'Quick Links';
      return { id, title, section, handler: () => { Turbo.visit(link.href); } }
    });
  }
}
