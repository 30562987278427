import { Controller } from 'stimulus';
import { Steven, ELEMENT_TYPES } from 'helpers/steven';

export default class extends Controller {
  logAcceptInviteClicked() {
    Steven.elementClicked({
      id: 'accept-collaboration-button',
      content: 'Accept and Start Collaboration',
      type: ELEMENT_TYPES.BUTTON,
      action: 'accept_invite_confirm_button_clicked',
    });
  }
}
