import { Controller } from 'stimulus';
import { Steven } from 'helpers/steven';

export default class extends Controller {
  static values = { tagId: String };

  connect() {
    this.sendStevenEvent();
  }

  sendStevenEvent() {
    Steven.entityCrud({
      entity_id: this.tagIdValue,
      entity_type: 'tag',
      action: 'created',
    });
  }
}
